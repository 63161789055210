<template>
  <div>
    <b-card v-if="!isLoading">
      <div class="d-flex align-items-center ">
        <b-avatar rounded="lg" variant="light-warning">
          <i class="card-icon ti ti-news " />
        </b-avatar>
        <h6 class="ml-1 mb-0">{{ news.title }}</h6>
      </div>

      <div class="mt-1" v-html="news.content" />
    </b-card>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Berita
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Component
import { BCard, BButton, BAvatar, BSpinner, BFormTextarea } from 'bootstrap-vue'

// *Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BCard,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,

      // default data
      newsId: this.$router.currentRoute.params.id,

      // data
      news: {},
    }
  },
  mounted() {
    client.get(`/news/${this.newsId}`).then((response) => {
      this.news = response.data.data

      this.isLoading = false
    })
  },
}
</script>

<style>
p {
  margin-bottom: 0;
}
</style>
